.chat-scroll::-webkit-scrollbar {
  width: 7px;
  /* width of the entire scrollbar */
}

.chat-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  ;
  /* color of the tracking area */
}

.chat-scroll::-webkit-scrollbar-thumb {
  background-color: #69727c;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.user-chat-content {
  max-width: 400px;
}

.MuiTab-root {
  @media (min-width: 600px) {
    min-width: 80px !important;
  }
}

.MuiTabScrollButton-root {
  width: 10px !important;
}

.user-status-box2 {
  background-color: $light;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  margin-top: 16px;
  display: block;

  .chat-user-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}


.second-split-view {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
  transition: all 0.4s;

  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.second-split-view-show {
      visibility: visible;
      transform: translateX(0);
    }
  }

}

.chat-avatar2 {

  margin: 0 16px 0 0
    /*rtl:0 0 0 16px*/
  ;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}

.MuiTabs-root {
  height: 20px !important;
  min-height: 20px;
  font-size: 10px;
}

.chat-message-list {
  height: calc(100vh - 275px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 320px);
  }
}

.chat-message-list-user {
  height: calc(78vh);

  @media (max-width: 991.98px) {
    height: calc(100vh - 320px);
  }
}

.view-lists {
  height: calc(100vh - 160px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 200px);
  }
}

.view-pages {
  height: calc(100vh - 100px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 140px);
  }
}

.view-SimpleBar {
  height: 100vh;
}

.view-atendimentos {
  height: calc(100vh - 250px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 200px);
  }
}

.view-full-pages {
  height: calc(100vh - 10px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 50px);
  }
}

.unread {
  font-weight: bold;
  color: #dbfff1;
}

.nav-tabs nav {
  overflow-y: auto;
}


.view-list {
  margin: 0;

  li {
    &.active {
      a {
        background-color: $light;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 15px 20px;
      color: $gray-600;
      transition: all 0.4s;
      border-top: 1px solid $sidebar-sub-bg;
      border-radius: 4px;

      &:hover {
        background-color: $light;
      }
    }

    .chat-user-message {
      font-size: 14px;
    }


    &.typing {
      .chat-user-message {
        color: $primary;
        font-weight: $font-weight-medium;

        .dot {
          background-color: $primary;
        }
      }
    }

    &.unread {
      .chat-user-message {
        color: $primary;
        font-weight: $font-weight-medium;

        .dot {
          background-color: $primary;
        }
      }
    }

    .unread-message {
      position: absolute;
      display: inline-block;
      right: 24px
        /*rtl:auto*/
      ;
      left: auto
        /*rtl:0*/
      ;
      top: 33px;

      .badge {
        line-height: 16px;
        font-weight: $font-weight-semibold;
        font-size: 10px;
      }
    }
  }
}

// &.online {
//   .user-status {
//       background-color: $success;
//   }
// }

// &.away {
//   .user-status {
//       background-color: $warning;
//   }
// }
.chat-user-img2 {
  position: relative;

  .user-status {
    width: 10px;
    height: 10px;
    background-color: $gray-500;
    border-radius: 50%;
    border: 2px solid $card-bg;
    position: absolute;
    right: 0
      /*rtl:auto*/
    ;
    left: auto
      /*rtl:0*/
    ;

    bottom: 0;
  }

  &.busy {
    .user-status {
      background-color: $danger;
    }
  }
}